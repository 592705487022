import React, { Component } from 'react';
import {connect} from 'react-redux';
import Container from '@material-ui/core/Container';
import paid from "../../assets/img/paid.png";

// const initialState = {
//   emailAdr: null,
// };

class SuccessfulBid extends Component {
  //state = initialState;

  // componentDidMount() {
  //   const {email} = this.props;
  //   this.setState({emailAdr: email})
  // }

  render() {
    //const {email} = this.props;
    const email = localStorage.getItem('email')

    //console.log("---- email", this.props.email);
    return (
      <div className="paid-page">
        <Container maxWidth="lg" component="section" className="paid-page_container">
          <img src={paid} alt="paid" className="paid-page_icon"/>
          <h1 className="paid-page_h1">заявка принята</h1>
          <p className="reply-to-address">
            ожидайте ответ на адрес {email && <a href={"mailto:"+{email}} className="reply-to-address_link">{email}</a>}
          </p>
          <p className="paid-page_support-service">служба поддержки</p>
          <p className="paid-page_email">
            <a href="mailto:help@tsaplineducation.ru" className="paid-page_email-link">help@tsaplineducation.ru</a>
          </p>
          <p className="paid-page_tel">
            <a href="https://t.me/utc13" className="paid-page_email-link" target="_blank">
              telegram
            </a>
          </p>
          <p className="paid-page_tel">
            <a href="https://vk.me/public122517309" className="paid-page_email-link" target="_blank">
              vkontakte
            </a>
          </p>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({orders}) => ({
  email: orders.email
})

export default connect(mapStateToProps, null)(SuccessfulBid);
