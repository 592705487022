import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import DescriptionList from './DescriptionList';
import RotateBanner from './RotateBanner';

import './style.scss';

const descriptionList = [
  'разовьешь авторство',
  'пересмотришь подход к клиентам',
  'прокачаешь портфолио и соцсети',
];

function Header() {
  return (
    <header className="main-header">
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          className="main-header-container"
        >
          <Hidden xsDown>
            <RotateBanner />
          </Hidden>
          <Hidden xsDown>
            <Grid item sm={12}>
              <Button href="#tariff" className="some-button main-header-button" disableRipple>участвовать</Button>
            </Grid>
          </Hidden>
          <Grid item sm={12}>
            <h1 className="main-header_h1">скачок в карьере фотографа</h1>
          </Grid>
          <Grid item sm={12}>
            <DescriptionList list={descriptionList} />
          </Grid>
          <Hidden smUp>
            <Grid item xl={12} sm={6}>
              <RotateBanner />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </header>
  )
}

export default Header;
