import React from 'react';

import {ReactComponent as BtnIcon} from "../../../assets/img/arrow-grey.svg";

function SlickArrowRight({ currentSlide, slideCount, ...props }) {
  return (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <BtnIcon />
    </button>
  )
}

export default SlickArrowRight;
