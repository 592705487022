import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import rootReducer from './redux'
import CssBaseline from '@material-ui/core/CssBaseline';

import './styles/main.scss';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

axios.defaults.withCredentials = true;

const store = createStore(rootReducer, applyMiddleware(thunk, logger))

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
