import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PriceBoxCard from './PriceBoxCard';

import './style.scss';

const PriceBoxList = [
  {
    id: 1,
    title: 'в общей тусовке',
    description: [
      {
        subtitle: 'общий чат',
        text: 'комьюнити совместно с другими участниками, где можно обсудить задания, посоветоваться и поддержать друг друга',
      },
      {
        subtitle: 'ежедневная поддержка',
        text: 'в телеграм-канале я буду давать комментарии: ругать, хвалить, подсказывать как сделать лучше или даже просто рекомендовать отдохнуть',
      },
      {
        subtitle: 'самоконтроль',
        text: 'к домашним заданиям есть дедлайны, но их соблюдение — целиком и полностью твоя ответственность',
      },
    ],
    price: 3500,
  },
  {
    id: 2,
    title: 'в моей группе',
    description: [
      {
        subtitle: 'комьюнити',
        text: 'совместно с другими участниками, где можно обсудить задания, посоветоваться и поддержать друг друга',
      },
      {
        text: <span className="price-box_bold">ежедневная поддержка</span>,
      },
      {
        text: 'в телеграм-канале я буду давать комментарии: ругать, хвалить, подсказывать как сделать лучше или даже просто рекомендовать отдохнуть',
      },
      {
        text: <span className="price-box_bold">каждые 2 недели созвон в Zoom в мини-группах по 6 человек</span>,
      },
      {
        text: <span className="price-box_bold">общий чат со мной, где я отвечаю на все вопросы</span>,
      },
      {
        text: <span className="price-box_bold">сопровождение на каждом задании</span>,
      },
      {
        text: <span className="price-box_bold">обратная связь и разбор ваших съемок</span>,
      },
      {
        text: <span className="price-box_bold">волшебный пинок</span>,
      },
    ],
    seats: 60,
    price: 20000,
  },
  {
    id: 3,
    title: 'лично со мной',
    description: [
      {
        text: <span><span className="price-box_bold">проведем</span> с тобой 10 персональных коучинговых сессий</span>,
      },
      {
        text: <span><span className="price-box_bold">индивидуальная</span> программа на 6 месяцев</span>,
      },
      {
        text: <span><span className="price-box_bold">сформулируем задачи</span>, разработаем стратегию, проанализируем результаты каждого задания и проекта</span>,
      },
      {
        text: <span><span className="price-box_bold">лично</span> отвечаю за то, чтобы ты пришел к поставленным целям</span>,
      },
    ],
    seats: 3,
    price: 96000,
    vip: 'vip',
  },
];

const renderBox = () => PriceBoxList.map((box, i) => <PriceBoxCard box={box} key={i} index={box.id} />);

function PriceBox() {
  return (
    <div className="tariff-block">
      <div className="tariff-block-grid-container">
        <Hidden smDown>
          <Grid item sm={12} className="1" id="tariff">
            <h2 className="choose-format_h2">
              <span>выбери свой формат коучинга</span>
            </h2>
          </Grid>
        </Hidden>
        {renderBox()}
      </div>
    </div>
  )
}

export default PriceBox;
