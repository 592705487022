import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ym, { YMInitializer } from 'react-yandex-metrika';

//import instagram from '../../assets/img/instagram.svg';
//import vk from '../../assets/img/vk.svg';
import photo from "../../assets/img/photo.png";
//import vector_1_8 from "../../assets/img/vector_1.8.svg";
//import vector_2_8 from "../../assets/img/vector_2.8.svg";
import tape_1 from "../../assets/img/tape_1.png";
import tape_2 from "../../assets/img/tape_2.png";

import './style.scss';

const useStyles = makeStyles({
  blockWrap: {
    background: '#000000',
    color: '#FFFFFF',
  },
  GridContainer: {
    '@media (min-width: 1024px)': {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      padding: '99px 24px 140px 24px',
      gridTemplateRows: '70% 30%',
      gridTemplateAreas: `"photo header" "photo text"`,
      position: 'relative',
    },
    '@media (min-width: 1280px)': {
      gridTemplateColumns: '50% 50%',
      gridTemplateRows: '70% 30%',
      maxWidth: 1280,
      padding: '99px 24px 140px 24px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'row',
    background: '#000000',
    padding: '35px 16px 25px 16px',
    '@media (min-width: 1024px)': {
      gridArea: 'header',
      padding: '0 16px 45px 60px',
    },
    '@media (min-width: 1280px)': {
      padding: 0,
      paddingLeft: 108,
      alignItems: 'flex-end',
    }
  },
  // socialH2: {
  //   width: '66%',
  //   fontFamily: 'Druk Wide Cy Web Bold',
  //   fontSize: 28,
  //   lineHeight: '32px',
  //   color: '#FFFFFF',
  //   marginBottom: 8,
  //   '@media (min-width: 600px)': {
  //     width: '100%',
  //     fontSize: 28,
  //     lineHeight: '64px',
  //   }
  // },
  socialWrap: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    position: 'relative',
  },
  socialLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 34,
    maxHeight: 24,
    '&:hover': {
      color: '#FFFFFF',
    },
    '& img': {
      height: 24,
      '@media (min-width: 1280px)': {
        height: 34,
      }
    }
  },
  socialListBlock: {
    display: 'inline',
    position: 'absolute',
    right: 16,
    bottom: 6,
    '@media (min-width: 1280px)': {
      right: 'auto',
      marginLeft: 50,
    }
  },
  socialList: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 38,
    listStyle: 'none',
    paddingLeft: 0,
  },
  socialListItem: {
    display: 'flex',
    alignItems: 'center',
    height: 38,
    '& + &': {
      marginLeft: 16,
      '@media (min-width: 1280px)': {
        marginLeft: 20,
      }
    }
  },
  photoContainerWrap: {
    display: 'none',
    '@media (min-width: 1024px)': {
      gridArea: 'photo',
      display: 'block',
    }
  },
  photoContainer: {
    minHeight: '508px',
    paddingTop: 65,
    paddingBottom: 25,
    background: 'url(' + photo + ');',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    '@media (min-width: 1280px)': {
      gridRow: '1/3',
      minHeight: '779px',
    },
    '&:before': {
      content: `url(${tape_2})`,
      position: 'absolute',
      bottom: -28,
      left: -28,
    },
    '&:after': {
      content: `url(${tape_1})`,
      position: 'absolute',
      top: -42,
      right: -42,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingTop: 65,
    paddingBottom: 25,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, #000000 100%), url(' + photo + ');',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: 560,
    '@media (min-width: 600px)': {
      minHeight: 'auto',
    },
    '@media (min-width: 1024px) and (max-width: 1279px)': {
      gridArea: 'text',
      display: 'block',
      minHeight: 'auto',
      justifyContent: 'flex-start',
      background: '#000000',
      padding: '57px 16px 0 44px',
    },
    '@media (min-width: 1280px)': {
      justifyContent: 'flex-start',
      background: '#000000',
      padding: '0',
    }
  },
  // h2: {
  //   fontFamily: 'Druk Wide Cy Web Bold',
  //   paddingLeft: 16,
  //   paddingRight: 16,
  //   fontSize: 28,
  //   lineHeight: '32px',
  //   letterSpacing: '-0.04em',
  //   color: '#FFFFFF',
  //   marginBottom: 25.69,
  //   '@media (min-width: 600px)': {
  //     fontSize: 92,
  //     lineHeight: '64px',
  //     marginBottom: 42,
  //     paddingLeft: 0,
  //     paddingRight: 0,
  //   },
  // },
  list: {
    listStyle: 'none',
    paddingLeft: 16,
    paddingRight: 16,
    '@media (min-width: 1280px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  listItem: {
    fontFamily: 'Druk Wide Cy Web Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    color: '#FFFFFF',
    marginBottom: 30,
    '&:last-child': {
      marginBottom: 0,
    },
    '@media (min-width: 1280px)': {
      fontSize: 28,
      lineHeight: '36px',
    },
  },
  vectorSixth: {
    position: 'relative',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    zIndex: 1,
  },
});

function PromotionalOffer() {
  const classes = useStyles();

  return (
    <section className={classes.blockWrap}>
      <Grid
        container
        direction="row"
        className={classes.GridContainer}
      >
        <div className={classes.photoContainerWrap}>
          <div className={classes.photoContainer}></div>
        </div>
        <div className={classes.socialContainer}>
          <Grid item sm={12}>
            <div className={classes.socialWrap}>
              <h2 className="promotional-offer_h3">
                <span>фотография — как спорт: нужна четкая программа тренировок</span>
              </h2>
              <p className="promotional-offer_p">секрет кубиков на животе — не в каких-то особых упражнениях и диетах. а в тренере, который составит план, будет корректировать тебя в процессе и не даст забросить</p>
            </div>
          </Grid>
        </div>
        <div className={classes.textContainer}>
          <Grid item md={12} sm={6}>
            <h2 className="promotional-offer_h2">«я готов стать твоим тренером»</h2>
          </Grid>
        </div>
      </Grid>
    </section>
  )
}


export default PromotionalOffer;
