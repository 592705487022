import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import {connect} from 'react-redux';
import $ from "jquery";
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Container from '@material-ui/core/Container';
import Slider from "react-slick";

import getImage from '../../utils/photos'
import { fetchComments } from '../../redux/comments';
import SlickArrowRight from './SlickArrowRight';
import SlickArrowLeft from './SlickArrowLeft';

import './style.scss';

const initialState = {
  slides: 1,
};

class ReviewsList extends Component {
  state = initialState;
  componentDidMount() {
    this.props.fetchComments()
    let windowWidth = window.innerWidth;
    if (windowWidth < 600) this.setState({slides: 1});
    if (windowWidth > 600) this.setState({slides: 3});
  }

  renderComments = comments => comments.map((comment, i) => {
    return (
      <li key={i} className="reviews_list-li">
        <div className="reviews_item">
          <div className="reviews_img-block">
            <a href={comment.socialNetworkLink} target="_blank" className="reviews_link">
              <div className={`reviews_img-wrap ${comment.socialNetwork}`}>
                <img src={getImage(comment.id)} alt={comment.fullName} className="reviews_img" />
              </div>
            </a>
            <div className="reviews_author-wrap">
              <div className="reviews_author">
                <span>{comment.fullName}</span>
              </div>
            </div>
          </div>
          <div className="reviews_text">{`${comment.text.slice(0, 160)} ...`}</div>
          <Link
            to={`/reviews#${i}`}
            className="reviews_link purple"
          >
            читать полностью
          </Link>
        </div>
      </li>
    )
  });

  render() {
    const {comments} = this.props;
    const {slides} = this.state;

    const renderSlides = () => {
      let windowWidth = window.innerWidth;
      if (windowWidth < 600) this.setState({slides: 1});
      if (windowWidth > 600) this.setState({slides: 3});
    }

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: slides,
      slidesToScroll: 1,
      prevArrow: <SlickArrowLeft />,
      nextArrow: <SlickArrowRight />,
    };

    window.addEventListener("resize", renderSlides);

    return (
      <div id="reviews-list" className="reviews">
        <div className="reviews_wrap">
          <h2 className="reviews_h2"><span>отзывы</span></h2>
          <ul className="reviews_list">
            <Slider {...settings}>
              {comments && this.renderComments(comments)}
            </Slider>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({comments}) => ({
  comments: comments.data
});

const mapDispatchToProps = {
  fetchComments,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsList);
