import {combineReducers} from 'redux'

import courses from './courses'
import remaining from './remaining'
import comments from './comments'
import orders from './orders'


export default combineReducers({
  courses,
  remaining,
  comments,
  orders,
})
