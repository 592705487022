import axios from 'axios';
import urlHost from '../utils/constants';

const urlModule = 'courses';

const FETCH_COURSES = `${urlModule}/FETCH_COURSES`;
const FETCH_COURSES_SUCCESS = `${urlModule}/FETCH_COURSES_SUCCESS`;
const FETCH_COURSES_FAIL = `${urlModule}/FETCH_COURSES_FAIL`;

const initialState = {
  data: null,
  isCoursesFetching: false,
  coursesError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSES:
      return {...state, isCoursesFetching: true, coursesError: null}
    case FETCH_COURSES_SUCCESS:
      return {...state, isCoursesFetching: false, data: action.data}
    case FETCH_COURSES_FAIL:
      return {...state, isCoursesFetching: false, coursesError: action.error}
    default:
      return state
  }
}
export const fetchCourses = () => dispatch => {
  dispatch({type: FETCH_COURSES})
  axios
    .get(`${urlHost}/api/courses`)
    .then(({data}) => {
      dispatch({type: FETCH_COURSES_SUCCESS, data: data})
    })
    .catch(error => dispatch({type: FETCH_COURSES_FAIL, coursesError: error}))
}
