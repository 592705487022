import React from 'react';
import Container from '@material-ui/core/Container';

import './style.scss';

function VideoBlock() {
  return (
    <div className="video-block">
      <Container maxWidth="lg" component="section">
        <h2 className="video-block_h2">
          <span className="video-block_span">как все устроено</span>
        </h2>
      </Container>
      <div className="video-block_clip">
        <iframe width="100%" height="100%" style={{maxHeight: "720px"}}
                src="https://player.vimeo.com/video/460105172?title=0&byline=0&portrait=0"
                frameBorder="0"
                allow="autoplay; fullscreen" allowFullScreen></iframe>
      </div>
    </div>
  )
}

export default VideoBlock;
