import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import './style.scss';

function DetailedDescription() {
  return (
    <div className="detailed_description_wrapper">
      <div className="detailed_description_wrapper_2">
        <Container maxWidth="lg" component="section" className="detailed_description">
          <Grid
            container
            direction="row"
            className="detailed_description_row"
          >
            <Grid item sm={12} className="detailed_description_h2-row detailed_description-title">
              <h2 className="detailed_description_h2">5 типов заданий — приведут тебя к росту</h2>
            </Grid>
            <Grid item sm={12} className="detailed_description_block description_1">
              <h3 className="detailed_description_h3">
                <span>снимаем огнище</span>
              </h3>
              <p className="detailed_description_p">научу организовывать съемки, работать с моделями, ставить свет и делать все остальное, про что ты уже посмотрел 100500 видосов на ютубе и никак не мог начать применять</p>
            </Grid>
            <Grid item sm={12} className="detailed_description_block description_2">
              <h3 className="detailed_description_h3">
                <span>организация процесса и тайм менеджмент.</span>
              </h3>
              <p className="detailed_description_p">одна из причин торможения в том, что ты пытаешься хвататься за все сразу. количество контента из сети мешает сосредоточиться и расставить приоритеты. здесь ты будешь получать то, что нужно когда это нужно. и уже через короткое время ты сможешь офигенно четко планировать свои проекты, занятость, отдых, творчество.</p>
            </Grid>
            <Grid item sm={12} className="detailed_description_block description_3">
              <h3 className="detailed_description_h3">
                <span>прокачиваем себя</span>
              </h3>
              <p className="detailed_description_p">разберу по полочкам твоих тараканов: что мешает тебе добиваться результата? почему ты не ведешь инстаграм, не обновлял портфолио 5 лет и факапишь, когда речь заходит о цене?</p>
            </Grid>
            <Grid item sm={12} className="detailed_description_block description_4">
              <h3 className="detailed_description_h3">
                <span>работаем на продвижение</span>
              </h3>
              <p className="detailed_description_p">помогу вывести на новый уровень твои соцсети и сайт, дам пошаговую инструкцию, что выкладывать, а что нет, как растить аудиторию и откуда брать заказы</p>
            </Grid>
            <Grid item sm={12} className="detailed_description_block description_5">
              <h3 className="detailed_description_h3">
                <span>разделяем творчество и коммерцию</span>
              </h3>
              <p className="detailed_description_p">причина многих провалов, многих депрессий, многих разочарований в том, что люди путают мух, котлеты и не могут разобраться в этом. этих тараканов мы вытравим самым надежным образом.</p>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  )
}

export default DetailedDescription;
