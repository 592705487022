import axios from 'axios';
import urlHost from '../utils/constants';

const urlModule = 'orders';

const FETCH_ORDERS = `${urlModule}/FETCH_ORDERS`;
const FETCH_ORDERS_SUCCESS = `${urlModule}/FETCH_ORDERS_SUCCESS`;
const FETCH_ORDERS_FAIL = `${urlModule}/FETCH_ORDERS_FAIL`;

const initialState = {
  orders: null,
  email: null,
  isOrdersFetching: false,
  ordersError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return {...state, isOrdersFetching: true, ordersError: null}
    case FETCH_ORDERS_SUCCESS:
      return {...state, isOrdersFetching: false, orders: action.orders}
    case FETCH_ORDERS_FAIL:
      return {...state, isOrdersFetching: false, ordersError: action.error}
    default:
      return state
  }
}

export const fetchOrdersCreateInvoice = (values) => dispatch => {
  dispatch({type: FETCH_ORDERS});
  axios
    .post(`${urlHost}/api/orders/create-invoice`, values)
    .then(({data}) => {
      dispatch({type: FETCH_ORDERS_SUCCESS, email: values.email});
      if (values.courseId === 3) {
        //window.routerHistory.push(`${urlHost}/paid-tariff-third`)
        localStorage.setItem('email', values.email);
        window.routerHistory.push('/successful-bid')
      } else {
        window.location.replace(`${data.paymentUrl}`);
      }
    })
    .catch(error => dispatch({type: FETCH_ORDERS_FAIL, ordersError: error}))
};
