import React, { Fragment } from 'react';

import LeftContent from '../LeftContent';
import RightContent from '../RightContent';

import './style.scss';

function AppContent() {
  return (
    <div className="app-content">
       <LeftContent />
       <RightContent />
    </div>
  );
}

export default AppContent;
