import React from 'react';
import Container from '@material-ui/core/Container';

import QuestionAnswerCardFirst from './QuestionAnswerCardFirst';
import QuestionAnswerCardSecond from './QuestionAnswerCardSecond';
import QuestionAnswerCardThird from './QuestionAnswerCardThird';
import QuestionAnswerCardFourth from './QuestionAnswerCardFourth';
import QuestionAnswerCardFifth from './QuestionAnswerCardFifth';
import QuestionAnswerCardSixth from './QuestionAnswerCardSixth';
import QuestionAnswerCardSeventh from './QuestionAnswerCardSeventh';

import './style.scss';

function QuestionAnswer() {
  return (
    <div className="question-answer">
      <Container maxWidth="lg" component="section">
        <h2 className="question-answer_h2"><span className="question-answer_span">вопрос / ответ</span></h2>
        <QuestionAnswerCardFirst />
        <QuestionAnswerCardSecond />
        <QuestionAnswerCardThird />
        <QuestionAnswerCardFourth />
        <QuestionAnswerCardFifth />
        <QuestionAnswerCardSixth />
        <QuestionAnswerCardSeventh />
      </Container>
    </div>
  );
}

export default QuestionAnswer;
