import React from 'react';
import $ from "jquery";

import './style.scss';

function RightContent() {



  // window.addEventListener("resize", () => {
  //   $('.marquee-lents').css('width', `${window.innerWidth + 32}px`)
  // });

  //style={{ height: `${$(document).height()}px` }}
  // style={{ height: '15000px' }}

  return (
    <div className="right-content">
      <div className='right-content_container'>
        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>

        <div className="right-content_marquee">портфолио </div>
        <div className="right-content_marquee">узнаваемость </div>
        <div className="right-content_marquee">съемки </div>
        <div className="right-content_marquee">гонорары </div>
        <div className="right-content_marquee">личный бренд </div>
        <div className="right-content_marquee">заказы </div>
      </div>
    </div>
  );
}

export default RightContent;
