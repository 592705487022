import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '../../../assets/img/ExpandMore.svg';

import './style.scss';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer'
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

function QuestionAnswerCardFirst() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="question-answer_card">
      <CardActions disableSpacing className="question-answer_head">
        <h3 className="question-answer_h3" onClick={handleExpandClick}>
          a что конкретно будет на коучинге?
        </h3>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <img src={ExpandMoreIcon} alt="Expand More"/>
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <p className="question-answer_p">
          Практические задания и четкие инструкции по их выполнению. Я не скажу тебе всю программу, так как это было бы странно — мне тренер в зале тоже не говорит. Но общий вектор мы точно обозначим.
        </p>

        <p className="question-answer_p">
          Общий список тем, который мы разжуем с тобой до манной каши:
        </p>

        <ol className="question-answer_p">
          <li>практика съемок — свет, модели, команда</li>
          <li>психология</li>
          <li>получение заказов</li>
          <li>соцсети</li>
          <li>фотодни</li>
          <li>переговоры с клиентами</li>
          <li>сайт и портфолио</li>
          <li>творчество</li>
          <li>работа с клиентами на съемочной площадке</li>
        </ol>

        <p className="question-answer_p">
          Как следствие:
        </p>

        <ul className="question-answer_p">
          <li>У тебя появится понимание, ЗАЧЕМ ты снимаешь</li>
          <li>У тебя появятся ясные цели, что тебе нужно в жизни для счастья.</li>
          <li>Твое портфолио пополнится крутыми проектами.</li>
          <li>У тебя сформируется свой стиль и авторский почерк.</li>
        </ul>
      </Collapse>
    </div>
  )
}

export default QuestionAnswerCardFirst;
