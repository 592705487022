import photo_1 from "../assets/photo/1.jpg"
import photo_2 from "../assets/photo/2.jpg"
import photo_3 from "../assets/photo/3.jpg"
import photo_4 from "../assets/photo/4.jpg"
import photo_5 from "../assets/photo/5.jpg"
import photo_6 from "../assets/photo/6.jpg"
import photo_7 from "../assets/photo/7.jpg"
import photo_8 from "../assets/photo/8.jpg"
import photo_9 from "../assets/photo/9.jpg"
import photo_10 from "../assets/photo/10.jpg"

import photo_11 from "../assets/photo/11.jpg"
import photo_12 from "../assets/photo/12.jpg"
import photo_13 from "../assets/photo/13.jpg"
import photo_14 from "../assets/photo/14.jpg"
import photo_15 from "../assets/photo/15.jpg"
import photo_16 from "../assets/photo/16.jpg"
import photo_17 from "../assets/photo/17.jpg"
import photo_18 from "../assets/photo/18.jpg"
import photo_19 from "../assets/photo/19.jpg"
import photo_20 from "../assets/photo/20.jpg"

import photo_21 from "../assets/photo/21.jpg"
import photo_22 from "../assets/photo/22.jpg"
import photo_23 from "../assets/photo/23.jpg"
import photo_24 from "../assets/photo/24.jpg"
import photo_25 from "../assets/photo/25.jpg"
import photo_26 from "../assets/photo/26.jpg"
import photo_27 from "../assets/photo/27.jpg"
import photo_28 from "../assets/photo/28.jpg"
import photo_29 from "../assets/photo/29.jpg"
import photo_30 from "../assets/photo/30.jpg"

import photo_31 from "../assets/photo/31.jpg"
import photo_32 from "../assets/photo/32.jpg"
import photo_33 from "../assets/photo/33.jpg"
import photo_34 from "../assets/photo/34.jpg"
import photo_35 from "../assets/photo/35.jpg"
import photo_36 from "../assets/photo/36.jpg"
import photo_37 from "../assets/photo/37.jpg"
import photo_38 from "../assets/photo/38.jpg"
import photo_39 from "../assets/photo/39.jpg"
import photo_40 from "../assets/photo/40.jpg"

import photo_41 from "../assets/photo/41.jpg"
import photo_42 from "../assets/photo/42.jpg"
import photo_43 from "../assets/photo/43.jpg"
import photo_44 from "../assets/photo/44.jpg"
import photo_45 from "../assets/photo/45.jpg"
import photo_46 from "../assets/photo/46.jpg"
import photo_47 from "../assets/photo/47.jpg"
import photo_48 from "../assets/photo/48.jpg"
import photo_49 from "../assets/photo/49.jpg"
import photo_50 from "../assets/photo/50.jpg"

import photo_51 from "../assets/photo/51.jpg"
import photo_52 from "../assets/photo/52.jpg"
import photo_53 from "../assets/photo/53.jpg"
import photo_54 from "../assets/photo/54.jpg"
import photo_55 from "../assets/photo/55.jpg"
import photo_56 from "../assets/photo/56.jpg"
import photo_57 from "../assets/photo/57.jpg"
import photo_58 from "../assets/photo/58.jpg"
import photo_59 from "../assets/photo/59.jpg"
import photo_60 from "../assets/photo/60.jpg"

import photo_61 from "../assets/photo/61.jpg"
import photo_62 from "../assets/photo/62.jpg"
import photo_63 from "../assets/photo/63.jpg"
import photo_64 from "../assets/photo/64.jpg"
import photo_65 from "../assets/photo/65.jpg"

const getImage = (id) => {
  switch (id) {
    case 1:
      return photo_1;
      break;
    case 2:
      return photo_2;
      break;
    case 3:
      return photo_3;
      break;
    case 4:
      return photo_4;
      break;
    case 5:
      return photo_5;
      break;
    case 6:
      return photo_6;
      break;
    case 7:
      return photo_7;
      break;
    case 8:
      return photo_8;
      break;
    case 9:
      return photo_9;
      break;
    case 10:
      return photo_10;
      break;
    case 11:
      return photo_11;
      break;
    case 12:
      return photo_12;
      break;
    case 13:
      return photo_13;
      break;
    case 14:
      return photo_14;
      break;
    case 15:
      return photo_15;
      break;
    case 16:
      return photo_16;
      break;
    case 17:
      return photo_17;
      break;
    case 18:
      return photo_18;
      break;
    case 19:
      return photo_19;
      break;
    case 20:
      return photo_20;
      break;
    case 21:
      return photo_21;
      break;
    case 22:
      return photo_22;
      break;
    case 23:
      return photo_23;
      break;
    case 24:
      return photo_24;
      break;
    case 25:
      return photo_25;
      break;
    case 26:
      return photo_26;
      break;
    case 27:
      return photo_27;
      break;
    case 28:
      return photo_28;
      break;
    case 29:
      return photo_29;
      break;
    case 30:
      return photo_30;
      break;
    case 31:
      return photo_31;
      break;
    case 32:
      return photo_32;
      break;
    case 33:
      return photo_33;
      break;
    case 34:
      return photo_34;
      break;
    case 35:
      return photo_35;
      break;
    case 36:
      return photo_36;
      break;
    case 37:
      return photo_37;
      break;
    case 38:
      return photo_38;
      break;
    case 39:
      return photo_39;
      break;
    case 40:
      return photo_40;
      break;
    case 41:
      return photo_41;
      break;
    case 42:
      return photo_42;
      break;
    case 43:
      return photo_43;
      break;
    case 44:
      return photo_44;
      break;
    case 45:
      return photo_45;
      break;
    case 46:
      return photo_46;
      break;
    case 47:
      return photo_47;
      break;
    case 48:
      return photo_48;
      break;
    case 49:
      return photo_49;
      break;
    case 50:
      return photo_50;
      break;
    case 51:
      return photo_51;
      break;
    case 52:
      return photo_52;
      break;
    case 53:
      return photo_53;
      break;
    case 54:
      return photo_54;
      break;
    case 55:
      return photo_55;
      break;
    case 56:
      return photo_56;
      break;
    case 57:
      return photo_57;
      break;
    case 58:
      return photo_58;
      break;
    case 59:
      return photo_59;
      break;
    case 60:
      return photo_60;
      break;
    case 61:
      return photo_61;
      break;
    case 62:
      return photo_62;
      break;
    case 63:
      return photo_63;
      break;
    case 64:
      return photo_64;
      break;
    case 65:
      return photo_65;
      break;
    default:
      return photo_1;
  }
};

export default getImage
