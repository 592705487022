import IMG_0001 from '../assets/best-photo/IMG_0001.jpg';
import IMG_0002 from '../assets/best-photo/IMG_0002.jpg';
import IMG_0003 from '../assets/best-photo/IMG_0003.jpg';
import IMG_0004 from '../assets/best-photo/IMG_0004.jpg';
import IMG_0005 from '../assets/best-photo/IMG_0005.jpg';
import IMG_0007 from '../assets/best-photo/IMG_0007.jpg';
import IMG_0008 from '../assets/best-photo/IMG_0008.jpg';
import IMG_0009 from '../assets/best-photo/IMG_0009.jpg';
import IMG_0010 from '../assets/best-photo/IMG_0010.jpg';
import IMG_0011 from '../assets/best-photo/IMG_0011.jpg';
import IMG_0012 from '../assets/best-photo/IMG_0012.jpg';
import IMG_0062 from '../assets/best-photo/IMG_0062.jpg';
import IMG_0064 from '../assets/best-photo/IMG_0064.jpg';
import IMG_0066 from '../assets/best-photo/IMG_0066.jpg';
import IMG_0067 from '../assets/best-photo/IMG_0067.jpg';
import IMG_0068 from '../assets/best-photo/IMG_0068.jpg';
import IMG_0075 from '../assets/best-photo/IMG_0075.jpg';
import IMG_0080 from '../assets/best-photo/IMG_0080.jpg';
import IMG_0082 from '../assets/best-photo/IMG_0082.jpg';
import IMG_0101 from '../assets/best-photo/IMG_0101.jpg';
import IMG_0127 from '../assets/best-photo/IMG_0127.jpg';
import IMG_0129 from '../assets/best-photo/IMG_0129.jpg';
import IMG_0242 from '../assets/best-photo/IMG_0242.jpg';
import IMG_0248 from '../assets/best-photo/IMG_0248.jpg';
import IMG_0251 from '../assets/best-photo/IMG_0251.jpg';
import IMG_0277 from '../assets/best-photo/IMG_0277.jpg';
import IMG_0281 from '../assets/best-photo/IMG_0281.jpg';
import IMG_0283 from '../assets/best-photo/IMG_0283.jpg';
import IMG_0284 from '../assets/best-photo/IMG_0284.jpg';
import IMG_0287 from '../assets/best-photo/IMG_0287.jpg';
import IMG_0288 from '../assets/best-photo/IMG_0288.jpg';
import IMG_0289 from '../assets/best-photo/IMG_0289.jpg';
import IMG_0291 from '../assets/best-photo/IMG_0291.jpg';
import IMG_0293 from '../assets/best-photo/IMG_0293.jpg';
import IMG_0355 from '../assets/best-photo/IMG_0355.jpg';
import IMG_0356 from '../assets/best-photo/IMG_0356.jpg';
import IMG_0358 from '../assets/best-photo/IMG_0358.jpg';
import IMG_0513 from '../assets/best-photo/IMG_0513.jpg';
import IMG_0521 from '../assets/best-photo/IMG_0521.jpg';
import IMG_0533 from '../assets/best-photo/IMG_0533.jpg';
import IMG_0538 from '../assets/best-photo/IMG_0538.jpg';
import IMG_0545 from '../assets/best-photo/IMG_0545.jpg';
import IMG_0547 from '../assets/best-photo/IMG_0547.jpg';
import IMG_0549 from '../assets/best-photo/IMG_0549.jpg';
import IMG_0551 from '../assets/best-photo/IMG_0551.jpg';
import IMG_0554 from '../assets/best-photo/IMG_0554.jpg';
import IMG_0556 from '../assets/best-photo/IMG_0556.jpg';
import IMG_0899 from '../assets/best-photo/IMG_0899.jpg';
import IMG_0901 from '../assets/best-photo/IMG_0901.jpg';
import IMG_0999 from '../assets/best-photo/IMG_0999.jpg';
import IMG_1206 from '../assets/best-photo/IMG_1206.jpg';
import IMG_1207 from '../assets/best-photo/IMG_1207.jpg';
import IMG_1209 from '../assets/best-photo/IMG_1209.jpg';
import IMG_1210 from '../assets/best-photo/IMG_1210.jpg';
import IMG_1228 from '../assets/best-photo/IMG_1228.jpg';
import IMG_1240 from '../assets/best-photo/IMG_1240.jpg';
import IMG_1243 from '../assets/best-photo/IMG_1243.jpg';
import IMG_1245 from '../assets/best-photo/IMG_1245.jpg';
import IMG_1246 from '../assets/best-photo/IMG_1246.jpg';
import IMG_9815 from '../assets/best-photo/IMG_9815.jpg';
import IMG_9823 from '../assets/best-photo/IMG_9823.jpg';
import IMG_9829 from '../assets/best-photo/IMG_9829.jpg';
import IMG_9840 from '../assets/best-photo/IMG_9840.jpg';
import IMG_9844 from '../assets/best-photo/IMG_9844.jpg';
import IMG_9987 from '../assets/best-photo/IMG_9987.jpg';
import IMG_9989 from '../assets/best-photo/IMG_9989.jpg';
import IMG_9995 from '../assets/best-photo/IMG_9995.jpg';

export const stsArr = [
  'IMG_0001',
  'IMG_0002',
  'IMG_0003',
  'IMG_0004',
  'IMG_0005',
  'IMG_0007',
  'IMG_0008',
  'IMG_0009',
  'IMG_0010',
  'IMG_0011',
  'IMG_0012',
  'IMG_0062',
  'IMG_0064',
  'IMG_0066',
  'IMG_0067',
  'IMG_0068',
  'IMG_0075',
  'IMG_0080',
  'IMG_0082',
  'IMG_0101',
  'IMG_0127',
  'IMG_0129',
  'IMG_0242',
  'IMG_0248',
  'IMG_0251',
  'IMG_0277',
  'IMG_0281',
  'IMG_0283',
  'IMG_0284',
  'IMG_0287',
  'IMG_0288',
  'IMG_0289',
  'IMG_0291',
  'IMG_0293',
  'IMG_0355',
  'IMG_0356',
  'IMG_0358',
  'IMG_0513',
  'IMG_0521',
  'IMG_0533',
  'IMG_0538',
  'IMG_0545',
  'IMG_0547',
  'IMG_0549',
  'IMG_0551',
  'IMG_0554',
  'IMG_0556',
  'IMG_0899',
  'IMG_0901',
  'IMG_0999',
  'IMG_1206',
  'IMG_1207',
  'IMG_1209',
  'IMG_1210',
  'IMG_1228',
  'IMG_1240',
  'IMG_1243',
  'IMG_1245',
  'IMG_1246',
  'IMG_9815',
  'IMG_9823',
  'IMG_9829',
  'IMG_9840',
  'IMG_9844',
  'IMG_9987',
  'IMG_9989',
  'IMG_9995',
];

const getStsImage = (id) => {
  switch (id) {
    case 0:
      return IMG_0001;
      break;
    case 1:
      return IMG_0002;
      break;
    case 2:
      return IMG_0003;
      break;
    case 3:
      return IMG_0004;
      break;
    case 4:
      return IMG_0005;
      break;
    case 5:
      return IMG_0007;
      break;
    case 6:
      return IMG_0008;
      break;
    case 7:
      return IMG_0009;
      break;
    case 8:
      return IMG_0010;
      break;
    case 9:
      return IMG_0011;
      break;
    case 10:
      return IMG_0012;
      break;
    case 11:
      return IMG_0062;
      break;
    case 12:
      return IMG_0064;
      break;
    case 13:
      return IMG_0066;
      break;
    case 14:
      return IMG_0067;
      break;
    case 15:
      return IMG_0068;
      break;
    case 16:
      return IMG_0075;
      break;
    case 17:
      return IMG_0080;
      break;
    case 18:
      return IMG_0082;
      break;
    case 19:
      return IMG_0101;
      break;
    case 20:
      return IMG_0127;
      break;
    case 21:
      return IMG_0129;
      break;
    case 22:
      return IMG_0242;
      break;
    case 23:
      return IMG_0248;
      break;
    case 24:
      return IMG_0251;
      break;
    case 25:
      return IMG_0277;
      break;
    case 26:
      return IMG_0281;
      break;
    case 27:
      return IMG_0283;
      break;
    case 28:
      return IMG_0284;
      break;
    case 29:
      return IMG_0287;
      break;
    case 30:
      return IMG_0288;
      break;
    case 31:
      return IMG_0289;
      break;
    case 32:
      return IMG_0291;
      break;
    case 33:
      return IMG_0293;
      break;
    case 34:
      return IMG_0355;
      break;
    case 35:
      return IMG_0356;
      break;
    case 36:
      return IMG_0358;
      break;
    case 37:
      return IMG_0513;
      break;
    case 38:
      return IMG_0521;
      break;
    case 39:
      return IMG_0533;
      break;
    case 40:
      return IMG_0538;
      break;
    case 41:
      return IMG_0545;
      break;
    case 42:
      return IMG_0547;
      break;
    case 43:
      return IMG_0549;
      break;
    case 44:
      return IMG_0551;
      break;
    case 45:
      return IMG_0554;
      break;
    case 46:
      return IMG_0556;
      break;
    case 47:
      return IMG_0899;
      break;
    case 48:
      return IMG_0901;
      break;
    case 49:
      return IMG_0999;
      break;
    case 50:
      return IMG_1206;
      break;
    case 51:
      return IMG_1207;
      break;
    case 52:
      return IMG_1209;
      break;
    case 53:
      return IMG_1210;
      break;
    case 54:
      return IMG_1228;
      break;
    case 55:
      return IMG_1240;
      break;
    case 56:
      return IMG_1243;
      break;
    case 57:
      return IMG_1245;
      break;
    case 58:
      return IMG_1246;
      break;
    case 59:
      return IMG_9815;
      break;
    case 60:
      return IMG_9823;
      break;
    case 61:
      return IMG_9829;
      break;
    case 62:
      return IMG_9840;
      break;
    case 63:
      return IMG_9844;
      break;
    case 64:
      return IMG_9987;
      break;
    case 65:
      return IMG_9989;
      break;
    case 66:
      return IMG_9995;
      break;
    default:
      return IMG_9844;
  }
};

export default getStsImage
