import React, { useState } from 'react';
import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility'

import './style.scss';

const marqueeList = [
  {
    item:
      <div className="marquee" style={{ whiteSpace: "nowrap" }}>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
      </div>,
    direction: 'toLeft',
    speed: 9,
  },
  {
    item:
      <div className="marquee" style={{ whiteSpace: "nowrap" }}>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
      </div>,
    direction: 'toRight',
    speed: 5,
  },
  {
    item:
      <div className="marquee" style={{ whiteSpace: "nowrap" }}>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
        <div className="marquee_item">марафон</div>
        <div className="marquee_item">а не спринт</div>
      </div>,
    direction: 'toLeft',
    speed: 7,
  }
];

function MarqueeMain() {
  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = (isVisible) => {
    setPageIsVisible(isVisible)
  }

  const getMove = () => setTimeout(() => true, 1000)

//offset={220}  move={getMove()}
  const renderMarquee = () => marqueeList.map((marquee, i) =>
    <Ticker speed={marquee.speed} direction={marquee.direction} key={i}>
      {() => marquee.item}
    </Ticker>
  )
  return (
    <PageVisibility onChange={handleVisibilityChange}>
      {pageIsVisible && (
        <div className="marquee_main">
          <div className="marquee_wrap">
            {renderMarquee()}
          </div>
        </div>
      )}
    </PageVisibility>
  )
}

export default MarqueeMain;












// import React from 'react';
// import Ticker from 'react-ticker';
// import Marquee from 'react-marquee-master';
//
// import './style.scss';
//
// const marqueeList = [
//   {
//     item: [
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//     ],
//     direction: 'left',
//     speed: 20,
//   },
//   {
//     item: [
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//     ],
//     direction: 'right',
//     speed: 16,
//   },
//   {
//     item: [
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//       <span>марафон</span>,
//       <span>а не спринт</span>,
//     ],
//     direction: 'left',
//     speed: 24,
//   }
// ];
//
// function MarqueeMain() {
//   const renderMarquee = () => marqueeList.map((marquee, i) =>
//     <Marquee
//       marqueeItems={marquee.item}
//       direction={marquee.direction}
//       delay={marquee.speed}
//       height={window.innerWidth < 600 ? 32 : 72}
//       marqueeContainerClassName="ticker_container"
//       marqueeItemClassName="ticker_item"
//       marqueeClassName="ticker"
//     />
//   );
//
//   return (
//     <div className="ticker-main">
//       {renderMarquee()}
//     </div>
//   )
// }
//
// export default MarqueeMain;

