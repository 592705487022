import React, { Fragment } from 'react';
//import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import Container from '@material-ui/core/Container';

import './style.scss';
import closeBtn from "../../assets/img/close-btn.svg";

function HaveAnyQuestions() {
  return (
    <Container maxWidth="lg" component="section" className="have-any-questions">
      <h2 id="have-any-questions" className="have-any-questions_h2">остались вопросы?</h2>
      <ul className="have-any-questions_contact-list">
        <li className="have-any-questions_mail">
          <a href="mailto:help@tsaplineducation.ru" target="_blank">
            help@tsaplineducation.ru
          </a>
        </li>
        <li className="have-any-questions_telegram">
          <a href="https://t.me/utc13" target="_blank">
            telegram
          </a>
        </li>
        <li className="have-any-questions_vk">
          <a href="https://vk.me/public122517309" target="_blank">
            vkontakte
          </a>
        </li>
      </ul>
      <ul className="have-any-questions_requisites">
        <li>
          <span>
            ИНН: 312820555103
          </span>
        </li>
        <li>
          <span>
            ОГРНИП: 317312300075160
          </span>
        </li>
        <li>
          <Link
            to="/offer#top"
          >
            договор оферты
          </Link>
        </li>
      </ul>
    </Container>
  )
}

export default HaveAnyQuestions;
