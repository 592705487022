import React, {Component} from 'react';
import {connect} from "react-redux";
import { HashLink as Link } from 'react-router-hash-link';
import Container from '@material-ui/core/Container';
import getImage from '../../utils/photos'
import { fetchComments } from '../../redux/comments';

import './style.scss';
import closeBtn from "../../assets/img/close-btn.svg";

class Reviews extends Component {
  componentDidMount() {
    const {comments} = this.props;
    if (!comments) this.props.fetchComments()
  }

  renderComments = comments => comments.map((comment, i) => {
    return (
      <div className="reviews-page_item" key={i}>
        <div className="reviews-page_img-block">
          <a href={comment.socialNetworkLink} target="_blank" className="reviews-page_link">
            <div className={`reviews-page_img-wrap ${comment.socialNetwork}`}>
              <img src={getImage(comment.id)} alt={comment.fullName} className="reviews-page_img" />
            </div>
          </a>
          <div className="reviews-page_author-wrap">
            <div className="reviews-page_author">
              <span>{comment.fullName}</span>
            </div>
          </div>
        </div>
        <div className="reviews-page_text">{comment.text}</div>
        <span id={i + 1}></span>
      </div>
    )
  });

  render() {
    const {comments} = this.props;

    return (
      <div className="reviews-page">
        <Container maxWidth="lg" component="section">
          <div className="reviews-page_close-btn-wrap">
            <div className="reviews-page_section">
              <h2 className="reviews-page_h2 reviews-page_h2--desktop"><span>отзывы</span> о коучинге игоря цаплина</h2>
              <h2 className="reviews-page_h2 reviews-page_h2--mobile">отзывы</h2>
              <Link to="/#reviews-list" className="reviews-page_close-btn">
                <img src={closeBtn} alt="close" className="reviews-page_close-btn-img" />
              </Link>
            </div>
          </div>
          <div className="reviews-page_wrap">
            {comments && this.renderComments(comments)}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({comments}) => ({
  comments: comments.data
});

const mapDispatchToProps = {
  fetchComments,
}

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
