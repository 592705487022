import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import clsx from 'clsx';
import {Field, Formik} from 'formik';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import closeBtn from "../../assets/img/close-btn.svg";
import './style.scss';

import { fetchOrdersCreateInvoice } from '../../redux/orders';

function Form({ handleClose, box, index, fetchOrdersCreateInvoice }) {
  let courseId = index;

  const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  const renderVipForm = () => (
    <Formik
      initialValues={{
        phone: '',
        fullName: '',
        email: '',
        vkId: '',
      }}
      validate={values => {
        const errors = {};
        if (!values.fullName) {
          errors.fullName = '* Введите свое имя';
        }
        if (!values.phone) {
          errors.phone = '* Введите свой телефон';
        }
        if (!values.email) {
          errors.email = '* Введите свой email адрес';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Проверьте email адрес';
        }
        if (index >= 2 && !values.vkId) {
          errors.vkId = '* Введите ссылку на страницу в VK';
        }
        return errors;
      }}
      onSubmit={(values) => {
        fetchOrdersCreateInvoice({
          ...values,
          courseId: courseId,
        })
        handleClose()
      }}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <div className="form-modal_close-btn-wrap">
              <button className="form-modal_close-btn" onClick={handleClose}>
                <img src={closeBtn} alt="close" className="form-modal_close-btn-img" />
              </button>
            </div>
            <h2 className="form-modal_h2">{`оформление подписки «${box.title}»`}</h2>
            {box.vip ? <p className="form-modal_limited-offer">ограниченное предложение, требующее предварительного знакомства</p> : ''}
            <Grid
              container
              direction="row"
              className="form-modal_container-row"
            >
              <Grid item xs={12} className="form-modal_container-item name">
                <p className="form-modal_p">
                  фио
                </p>
                <Field
                  type="text"
                  name="fullName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullName}
                  className="form-modal_field"
                  autoFocus
                />
                {errors.fullName && touched.fullName && errors.fullName}
              </Grid>
              <Grid item xs={12} className="form-modal_container-item tel">
                <p className="form-modal_p">
                  телефон
                </p>
                <Field
                  type="tel"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  className="form-modal_field"
                  placeholder="+7"
                />
                {errors.phone && touched.phone && errors.phone}
              </Grid>
              <Grid item xs={12} className="form-modal_container-item email">
                <p className="form-modal_p">
                  email
                </p>
                <Field
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className="form-modal_field"
                />
                {errors.email && touched.email && errors.email}
              </Grid>
              {
                index >= 2 ?
                  <Fragment>
                    <Grid item xs={12} className="form-modal_container-item social">
                      <p className="form-modal_p">
                        vkontakte
                      </p>
                      <Field
                        type="text"
                        name="vkId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.vkId}
                        className="form-modal_field"
                        placeholder="vk.com/"
                      />
                      {errors.vkId && touched.vkId && errors.vkId}
                    </Grid>
                  </Fragment> : null
              }

              {box.vip ?
                <Fragment>
                  <Grid item xs={12} className="form-modal_container-item message">
                    <p className="form-modal_limited-offer_p form-modal_limited-offer_p--margin">лично свяжусь с вами для обсуждения деталей</p>
                  </Grid>
                  <Hidden smDown>
                    <Grid item xs={12} className="form-modal_container-item price">
                      <button
                        type="submit"
                        disabled={
                          !values.fullName || !values.phone || !values.email || !values.vkId || isSubmitting
                        }
                        className={
                          clsx('form-modal_submit-btn',
                            `${box.vip ? 'vip' : ''}`,
                            !values.fullName || !values.phone || !values.email || !values.vkId || isSubmitting ?
                              'vip--disabled' : ''
                          )
                        }
                      >
                        {box.vip ? "отправить" : "оплатить"}
                      </button>
                    </Grid>
                  </Hidden>
                </Fragment> :
                <Fragment>
                  <Grid item xs={12} className="form-modal_container-item message">
                    <p className="form-modal_p price-p">
                      к оплате
                    </p>
                    <p className="form-modal_price-p">
                      {`${numberWithSpaces(box.price)} ₽`}
                    </p>
                  </Grid>
                  <Grid item xs={12} className="form-modal_container-item price">
                    <Hidden smDown>
                      <button
                        type="submit"
                        disabled={
                          !values.fullName || !values.phone || !values.email || !values.vkId || isSubmitting
                        }
                        className={
                          clsx('form-modal_submit-btn',
                            `${box.vip ? 'vip' : ''}`,
                            !values.fullName || !values.phone || !values.email || !values.vkId || isSubmitting ?
                              'vip--disabled' : ''
                          )
                        }
                      >
                        {box.vip ? "отправить" : "оплатить"}
                      </button>
                    </Hidden>
                  </Grid>
                </Fragment>}
            </Grid>
          </Container>
          <Hidden mdUp>
            <button
              type="submit"
              disabled={
                !values.fullName || !values.phone || !values.email || !values.vkId || isSubmitting
              }
              className={
                clsx('form-modal_submit-btn',
                  `${box.vip ? 'vip' : ''}`,
                  !values.fullName || !values.phone || !values.email || !values.vkId || isSubmitting ?
                    'vip--disabled' : ''
                )
              }
            >
              {box.vip ? "отправить" : "оплатить"}
            </button>
          </Hidden>
        </form>
      )}
    </Formik>
  );

  const renderForm = () => (
    <Formik
      initialValues={{
        phone: '',
        fullName: '',
        email: '',
        vkId: '',
      }}
      validate={values => {
        const errors = {};
        if (!values.fullName) {
          errors.fullName = '* Введите свое имя';
        }
        if (!values.phone) {
          errors.phone = '* Введите свой телефон';
        }
        if (!values.email) {
          errors.email = '* Введите свой email адрес';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Проверьте email адрес';
        }
        if (index >= 2 && !values.vkId) {
          errors.vkId = '* Введите ссылку на страницу в VK';
        }
        return errors;
      }}
      onSubmit={(values, {resetForm}) => {
        fetchOrdersCreateInvoice({
          ...values,
          courseId: courseId,
        })
        resetForm({values: ''})
      }}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <div className="form-modal_close-btn-wrap">
              <button className="form-modal_close-btn" onClick={handleClose}>
                <img src={closeBtn} alt="close" className="form-modal_close-btn-img" />
              </button>
            </div>
            <h2 className="form-modal_h2">{`оформление подписки «${box.title}»`}</h2>
            {box.vip ? <p className="form-modal_limited-offer">ограниченное предложение, требующее предварительного знакомства</p> : ''}
            <Grid
              container
              direction="row"
              className="form-modal_container-row"
            >
              <Grid item xs={12} className="form-modal_container-item name">
                <p className="form-modal_p">
                  фио
                </p>
                <Field
                  type="text"
                  name="fullName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullName}
                  className="form-modal_field"
                  autoFocus
                />
                {errors.fullName && touched.fullName && errors.fullName}
              </Grid>
              <Grid item xs={12} className="form-modal_container-item tel">
                <p className="form-modal_p">
                  телефон
                </p>
                <Field
                  type="tel"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  className="form-modal_field"
                  placeholder="+7"
                />
                {errors.phone && touched.phone && errors.phone}
              </Grid>
              <Grid item xs={12} className="form-modal_container-item email">
                <p className="form-modal_p">
                  email
                </p>
                <Field
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className="form-modal_field"
                />
                {errors.email && touched.email && errors.email}
              </Grid>
              {
                index >= 2 ?
                  <Fragment>
                    <Grid item xs={12} className="form-modal_container-item social">
                      <p className="form-modal_p">
                        vkontakte
                      </p>
                      <Field
                        type="text"
                        name="vkId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.vkId}
                        className="form-modal_field"
                        placeholder="vk.com/"
                      />
                      {errors.vkId && touched.vkId && errors.vkId}
                    </Grid>
                  </Fragment> : null
              }

              {box.vip ?
                <Fragment>
                  <Grid item xs={12} className="form-modal_container-item message">
                    <p className="form-modal_limited-offer_p">лично свяжусь с вами для обсуждения деталей</p>
                  </Grid>
                  <Grid item xs={12} className="form-modal_container-item price">
                    <Hidden smDown>
                      <button
                        type="submit"
                        disabled={
                          !values.fullName || !values.phone || !values.email || isSubmitting
                        }
                        className={
                          clsx('form-modal_submit-btn',
                            `${box.vip ? 'vip' : ''}`,
                            !values.fullName || !values.phone || !values.email || isSubmitting ?
                              'form-modal_submit-btn--disabled' : ''
                          )
                        }
                      >
                        {box.vip ? "отправить" : "оплатить"}
                      </button>
                    </Hidden>
                  </Grid>
                </Fragment> :
                <Fragment>
                  <Grid item xs={12} className="form-modal_container-item message">
                    <p className="form-modal_p price-p">
                      к оплате
                    </p>
                    <p className="form-modal_price-p">
                      {`${box.price} ₽`}
                    </p>
                  </Grid>
                  <Grid item xs={12} className="form-modal_container-item price">
                    <Hidden smDown>
                      <button
                        type="submit"
                        disabled={
                          !values.fullName || !values.phone || !values.email || isSubmitting
                        }
                        className={
                          clsx('form-modal_submit-btn',
                            `${box.vip ? 'vip' : ''}`,
                            !values.fullName || !values.phone || !values.email || isSubmitting ?
                              'form-modal_submit-btn--disabled' : ''
                          )
                        }
                      >
                        {box.vip ? "отправить" : "оплатить"}
                      </button>
                    </Hidden>
                  </Grid>
                </Fragment>}
            </Grid>
          </Container>
          <Hidden mdUp>
            <button
              type="submit"
              disabled={
                !values.fullName || !values.phone || !values.email || isSubmitting
              }
              className={
                clsx('form-modal_submit-btn',
                  `${box.vip ? 'vip' : ''}`,
                  !values.fullName || !values.phone || !values.email || isSubmitting ?
                    'form-modal_submit-btn--disabled' : ''
                )
              }
            >
              {box.vip ? "отправить" : "оплатить"}
            </button>
          </Hidden>
        </form>
      )}
    </Formik>
  );

  return (
    <div>
      {index >=2 ? renderVipForm() : renderForm()}
    </div>
  )
}

const mapStateToProps = ({orders}) => ({
  orders: orders.orders
});

const mapDispatchToProps = {
  fetchOrdersCreateInvoice,
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
