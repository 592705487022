import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import './style.scss';

function CallToAction() {
  return (
    <Hidden smUp>
      <Button href="#tariff" className="some-button participate-btn" disableRipple>участвовать</Button>
    </Hidden>
  )
}

export default CallToAction;
