import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import './style.scss';

function ChooseFormat() {
  return (
    <div className="choose-format">
      <Container maxWidth="lg" component="section">
        <Grid
          container
          direction="row"
          className=""
        >
          <Grid item sm={8} className="">
            <h3 className="choose-format_h3">
              <span>я хочу, чтобы у тебя сформировалась привычка работать над развитием и продвижением регулярно. не только во время, но и после коучинга</span>
            </h3>
          </Grid>
          <Grid item sm={4} className="">
            <p className="choose-format_p">поэтому коучинг длится 2,5 месяца, а на каждый цикл с заданиями дается 2 недели. чтобы работа шла в адекватном темпе без желания свалиться от истощения в середине.</p>
          </Grid>
          <Hidden mdUp>
            <Grid item sm={12} className="2" id="tariff">
              <h2 className="choose-format_h2">
                <span>выбери свой формат коучинга</span>
              </h2>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  )
}

export default ChooseFormat;
