import React from 'react';
import './style.scss';

function DescriptionList( {list} ) {
  const renderDescription = () => list.map(item =>
    <li className="description-list_item">
      <span>{item}</span>
    </li>
  );

  return (
    <ul className="description-list">
      {renderDescription()}
    </ul>
  )
}

export default DescriptionList;
