import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import '../../styles/offer-page.scss';
import { Link } from "react-router-dom";
//mport {HashLink as Link} from "react-router-hash-link";
import closeBtn from "../../assets/img/close-btn.svg";

function Offer() {
  return (
    <Container id="top" maxWidth="lg" component="section" className="offer-page_container">
      <div className="offer-page_close-btn-wrap">
        <Link to="/" className="offer-page_close-btn">
          <img src={closeBtn} alt="close" className="offer-page_close-btn-img" />
        </Link>
      </div>
      <h1 className="offer-page_h1">Договор оферты</h1>
      <p className="offer-page_p">Настоящий договор является официальным предложением (публичной офертой) адресованным любому физическому лицу, в дальнейшем именуемому Заказчик, согласившемуся с условиями настоящей публичной оферты, путем ее полного и безоговорочного акцепта.</p>
      <p className="offer-page_p">Заказчик обязан полностью ознакомиться с настоящим документом до момента внесения оплаты.</p>
      <p className="offer-page_p">Индивидуальный предприниматель Цаплин Игорь Александрович (ОГРНИП 317312300075160, ИНН 312820555103, юридический адрес: 143444, г. Красногорск, ул Ткацкой фабрики д.18, кв 117), именуемая в дальнейшем — Исполнитель выражает намерение заключить договор об оказании платных образовательных услуг с Заказчиком на условиях настоящей оферты (далее —«Договор»).</p>
      <h2 className="offer-page_h2">1. Термины и определения</h2>
      <p className="offer-page_p">В целях настоящей оферты нижеприведенные термины используются в следующих значениях:</p>
      <p className="offer-page_p">Оферта — настоящий документ опубликованный на сайте https://tsaplineducation.ru</p>
      <p className="offer-page_p">Акцепт Оферты — полное и безоговорочное принятие Оферты путем осуществления действий, указанных в п. 2.2. Оферты. Акцепт Оферты создает Договор.</p>
      <p className="offer-page_p">Заказчик — совершеннолетнее лицо, осуществившее Акцепт Оферты, являющееся потребителем платных образовательных услуг по заключенному Договору, либо правомочный представитель несовершеннолетнего лица, которое будет потребителем платных образовательных услуг.</p>
      <p className="offer-page_p">Договор — договор между Заказчиком и Исполнителем на предоставление образовательных услуг, который заключается посредством Акцепта Оферты.</p>
      <p className="offer-page_p">Сайт — интернет ресурс, содержащий полную информацию о содержании и стоимости курсов, размещенный по адресу https://tsaplineducation.ru.</p>
      <p className="offer-page_p">Тариф — набор услуг, входящих в стоимость курса.</p>
      <p className="offer-page_p">Площадка — помещение проведения обучающего курса. Один или несколько ресурсов из списка согласно Тарифу: https://t.me/joinchat/AAAAAFPyLEVUexIUEBvMFg,
      https://t.me/joinchat/AAAAAExUUjj48XLrIw6_wA, https://vk.com/tsaplineducation_2020</p>

      <h2 className="offer-page_h2">2. Предмет договора</h2>
      <p className="offer-page_p">2.1. Исполнитель предоставляет, а Заказчик оплачивает информационно-консультационные услуги в рамках выбранного курса.</p>
      <p className="offer-page_p">2.2. Оплатой Заказчик выражает свое полное и безоговорочное принятие условий настоящего договора (акцепт).</p>
      <p className="offer-page_p">2.3. Стоимость услуг по Договору сообщается на Сайте и составляет сумму, указанную для конкретного курса, интересующего Заказчика, в соответствии с тарифом, указанным на странице курса.</p>
      <h2 className="offer-page_h2">3. Обязанности исполнителя</h2>
      <p className="offer-page_p">Исполнитель обязан:</p>
      <p className="offer-page_p">3.1. Организовать и обеспечить надлежащее качественное исполнение услуг, предусмотренных в п. 2.1. настоящего Договора.</p>
      <p className="offer-page_p">3.2. Обеспечить Заказчика всеми необходимыми учебными методическими материалами согласно оплаченному Тарифу.</p>
      <p className="offer-page_p">3.3. В случае отсутствия доступа к учебным материалам в связи с техническими неполадками Сайта Исполнитель обязан устранить неполадки либо предоставить альтернативный доступ к учебным материалам.</p>
      <h2 className="offer-page_h2">4. Обязанности заказчика</h2>
      <p className="offer-page_p">4.1. Для организации доступа к учебным материалам Заказчик обеспечить проход на Площадку проведения обучающего курса.</p>
      <p className="offer-page_p">4.2. Исполнитель имеет право отказать Заказчику в оказании услуг в случае предоставления Заказчиком заведомо неверной (ложной) информации.</p>
      <p className="offer-page_p">4.3. Заказчик обязан надлежащим образом выполнять задания по подготовке к занятиям.</p>
      <p className="offer-page_p">4.4. Заказчик обязан строго следовать инструкциям преподавателя при выполнении заданий.</p>
      <h2 className="offer-page_h2">5. Права исполнителя и заказчика</h2>
      <p className="offer-page_p">5.1. Исполнитель вправе для оказания услуг привлекать третьих лиц, отвечая за их действия.</p>
      <p className="offer-page_p">5.2. Исполнитель вправе отказать Заказчику в оказании новых услуг, если Заказчик в период обучения допускал нарушения, предусмотренные гражданским законодательством и настоящим договором, и дающие Исполнителю право в одностороннем порядке отказаться от исполнения договора.</p>
      <p className="offer-page_p">5.3. Заказчик вправе требовать от Исполнителя предоставления информации по вопросам, касающимся организации и обеспечения надлежащего исполнения услуг, предусмотренных разделом 2 настоящего договора;</p>
      <p className="offer-page_p">5.4. Исполнитель имеет право незамедлительно прекратить оказание образовательных услуг с возвращением денежных средств за оплаченное обучение, в случае проявления со стороны Заказчика агрессии или неуважительного отношения.</p>
      <h2 className="offer-page_h2">6. Оплата услуг и возврат денежных средств</h2>
      <p className="offer-page_p">6.1. Заказчик оплачивает услуги, указанные в разделе 2.1 Договора в российских рублях. Оплата предоставляемых услуг производится на основании счета на оплату. Формирование счета на оплату производится после выбора курса на Сайте. Оплата счета происходит путем выбора подходящего способа оплаты на Сайте после нажатия кнопки «Подтвердить заказ».</p>
      <p className="offer-page_p">6.2. Заказчик производит оплату услуг Исполнителя до начала обучения в размере 100% предоплаты путем перечисления безналичных денежных средств Исполнителю на расчетный счет Исполнителя. Датой оплаты будет считаться день зачисления денежных средств на расчетный счет Исполнителя.</p>
      <p className="offer-page_p">6.3. Возврат денежных средств Заказчику может быть произведен только по одной из причин, указанных в п. 9 данного договора. Возврат средств производится только на личный счет Заказчика в течение 30 календарных дней с момента выполнения п. 9.</p>

      <h2 className="offer-page_h2">7. Соблюдение авторского права и конфиденциальность</h2>
      <p className="offer-page_p">7.1. Заказчик обязан соблюдать авторские права школы Исполнителя на все материалы курса в соответствии с Законом об авторских правах.</p>
      <p className="offer-page_p">7.2. Заказчику запрещается копировать какие-либо видео-, фото- или текстовые материалы.</p>
      <p className="offer-page_p">7.3. Заказчику запрещено использовать полученные от Исполнителя учебные материалы в коммерческих целях, от своего имени, размещать в открытом доступе, передавать третьим лицам.</p>
      <p className="offer-page_p">7.4. Факт заключения настоящего Договора не рассматривается Сторонами как конфиденциальная информация</p>
      <p className="offer-page_p">7.5. Стороны обязуются не разглашать информацию, полученную Сторонами в ходе выполнения своих обязательств по настоящему Договору, за исключением случаев, когда Сторона обязана предоставить такую информацию в соответствии с действующим законодательством, применимым к Договору, или было получено согласие на разглашение такой информации.</p>
      <h2 className="offer-page_h2">8. Обработка персональных данных заказчика</h2>
      <p className="offer-page_p">8.1. Персональные данные Заказчика обрабатываются в соответствии с Федеральным Законом «О персональных данных» № 152-ФЗ.</p>
      <p className="offer-page_p">8.2. При регистрации на Сайте Заказчик предоставляет следующую информацию: аккаунт в сервисе фамилию, имя, контактный номер телефона, адрес электронной почты.</p>
      <p className="offer-page_p">8.3. Предоставляя свои персональные данные Исполнителю, Заказчик соглашается на их обработку Исполнителем, в том числе в целях выполнения Исполнителем обязательств перед Заказчиком в рамках настоящей публичной оферты , продвижения Исполнителем товаров и услуг, проведения электронных и sms опросов, контроля результатов маркетинговых акций, клиентской поддержки, проведение розыгрышей призов среди Заказчиков, контроля удовлетворенности Заказчика, а также качества услуг, оказываемых Исполнителем.</p>
      <p className="offer-page_p">8.4. Под обработкой персональных данных понимается любое действие (операция) или совокупность действий (операций), совершаемых Исполнителем с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение) извлечение, использование, обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
      <p className="offer-page_p">8.5. Исполнитель вправе использовать технологию «cookies». «Cookies» не содержат конфиденциальную информацию. Заказчик настоящим дает согласие на сбор, анализ и использование cookies, в том числе третьими лицами для целей формирования статистики и оптимизации рекламных сообщений.</p>
      <p className="offer-page_p">8.6. Исполнитель получает информацию об ip-адресе посетителя Сайта. Данная информация не используется для установления личности посетителя.</p>
      <p className="offer-page_p">8.7. Исполнитель не несет ответственности за сведения, предоставленные Заказчиком на Сайте в общедоступной форме.</p>
      <h2 className="offer-page_h2">9. Основания и условия изменения и расторжения договора</h2>
      <p className="offer-page_p">9.1. Условия, на которых заключен настоящий договор, могут быть изменены либо по соглашению сторон, либо в соответствии с действующим законодательством Российской Федерации.</p>
      <p className="offer-page_p">9.2. Настоящий договор может быть расторгнут по соглашению сторон. По инициативе одной из сторон договор может быть расторгнут по основаниям, предусмотренным действующим законодательством Российской Федерации. В случае расторжения договора, Исполнитель возвращает Заказчику оплату за курс в соответствии с п. 6.3 Договора.</p>
      <p className="offer-page_p">9.3. Исполнитель вправе отказаться от исполнения договора, если Заказчик нарушил п.7.1 – 7.5 настоящего договора без возврата денежных средств.</p>
      <p className="offer-page_p">9.4. Договор считается расторгнутым со дня письменного уведомления Исполнителем Заказчика об отказе от исполнения договора.</p>
      <h2 className="offer-page_h2">10. Ответственность за неисполнение или ненадлежащее исполнение обязательств по настоящему договору</h2>
      <p className="offer-page_p">10.1. В случае неисполнения или ненадлежащего исполнения сторонами обязательств по настоящему договору они несут ответственность, предусмотренную гражданским законодательством и законодательством о защите прав потребителей, на условиях, установленных этим законодательством.</p>
      <p className="offer-page_p">10.2. Ни одна из Сторон не будет нести ответственности за полное или частичное неисполнение любой из своих обязанностей по настоящему Договору, если неисполнение будет являться следствием обстоятельств непреодолимой силы. Сторона, для которой создалась невозможность исполнения обстоятельств, обязана немедленно в письменном виде уведомить другую сторону о наступлении, предполагаемом сроке действия и прекращения вышеуказанных обстоятельств. Неуведомление или несвоевременное уведомление лишает Стороны права ссылаться на любое вышеуказанное обстоятельство как на основание, освобождающее от ответственности за неисполнение обязательства.</p>
      <h2 className="offer-page_h2">11. Срок действия договора и другие условия</h2>
      <p className="offer-page_p">11.1. Настоящий Договор вступает в силу с момента акцепта Заказчиком данной Публичной оферты и действует до полного исполнения Сторонами взятых на себя обязательств.</p>
      <p className="offer-page_p">11.2. Исполнитель оставляет за собой право внести изменения в настоящий Договор в любой момент по своему усмотрению, такие изменения вступают в силу с момента размещения изменённого текста Договора, если иной срок вступления изменений в силу не определён дополнительно в тексте публикации.</p>
      <p className="offer-page_p">11.3. Стороны считают обучение законченным, если Заказчику был предоставлен полный обучающий курс.</p>
      <p className="offer-page_p">Исполнитель:<br />
      ИП Цаплин Игорь Александрович.<br />
      Юридический адрес: 143444, г. Красногорск, ул Ткацкой фабрики д.18, кв 117<br />
      ИНН 471303586208<br />
      ОГРНИП: 317312300075160</p>
      <p className="offer-page_p">Банковские реквизиты:<br />
      Расчетный счет 40802810402500015801<br />
      ФК ОТКРЫТИЕ В ГОРОДЕ МОСКВЕ<br />
      БИК 044525999</p>
    </Container>
  )
}

export default Offer;
