import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import '../../styles/paid-page.scss';
import paid from '../../assets/img/paid.png';
import telegram from '../../assets/img/telegram_icon_btn.svg';

function PaidTariffFirst() {
  return (
    <div className="paid-page">
      <Container maxWidth="lg" component="section" className="paid-page_container">
        <img src={paid} alt="paid" className="paid-page_icon"/>
        <h1 className="paid-page_h1">оплачено</h1>
        <h4 className="paid-page_tariff-name">«в общей тусовке»</h4>
        <p className="paid-page_p">письмо отправлено на почту</p>
        <Grid
          container
          direction="row"
          className="paid-page_container-grid"
          spacing={2}
        >
          <Grid item md={6}>
            <p className="paid-page_botp">поможет определить твои цели<br />и задачи на коучинге</p>
            <a href="https://forms.gle/Tc9nu5kiq5ZFPghc6" target="_blank" className="paid-page_bot-button button_questionnaire">
              заполнить анкету
            </a>
          </Grid>
          <Grid item md={6}>
            <p className="paid-page_botp">перейдите в телеграм бота и следуйте инструкциям</p>
            <a href="https://t.me/tsaplineducation_bot" target="_blank" className="paid-page_bot-button button_telegram">
              <img src={telegram} alt="telegram"/>
            </a>
          </Grid>
        </Grid>
        <p className="paid-page_support-service">служба поддержки</p>
        <p className="paid-page_email">
          <a href="mailto:help@tsaplineducation.ru" className="paid-page_email-link">help@tsaplineducation.ru</a>
        </p>
        <p className="paid-page_tel">
          <a href="https://t.me/utc13" className="paid-page_email-link" target="_blank">
            telegram
          </a>
        </p>
        <p className="paid-page_tel">
          <a href="https://vk.me/public122517309" className="paid-page_email-link" target="_blank">
            vkontakte
          </a>
        </p>
      </Container>
    </div>
  );
}

export default PaidTariffFirst;
