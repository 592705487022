import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '../../../assets/img/ExpandMore.svg';

import './style.scss';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer'
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

function QuestionAnswerCardFourth() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="question-answer_card">
      <CardActions disableSpacing className="question-answer_head">
        <h3 className="question-answer_h3" onClick={handleExpandClick}>
          коучинг окупится?
        </h3>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <img src={ExpandMoreIcon} alt="Expand More"/>
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <p className="question-answer_p">
          Однозначно. Если вы выполните все задания, которые я скажу, и дойдете до конца — он окупится многократно ещё до его окончания.
        </p>
      </Collapse>
    </div>
  )
}

export default QuestionAnswerCardFourth;
