import React, { useState } from 'react';
import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility'

import './style.scss';

const marquee = {
  item:
    <div className="marquee">
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
      <div className="marquee_item">цаплин коучинг</div>
    </div>,
  direction: 'toLeft',
  speed: 7,
};

function MarqueeTop() {
  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = (isVisible) => {
    setPageIsVisible(isVisible)
  }

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      {pageIsVisible && (
        <div className="marquee_wrap horizontal">
          <Ticker speed={marquee.speed} direction={marquee.direction}>
            {() => marquee.item}
          </Ticker>
        </div>
      )}
    </PageVisibility>
  )
}

export default MarqueeTop;


















// import React from 'react';
// import Ticker from 'react-ticker';
// import Marquee from 'react-marquee-master';
//
// import './style.scss';
//
// const marquee = {
//   item:[
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//     <span>цаплин коучинг</span>,
//   ],
//   direction: 'right',
//   speed: 10,
// };
//
// function MarqueeTop() {
//   return (
//     <div className="ticker_wrap">
//       <Marquee
//         marqueeItems={marquee.item}
//         direction={marquee.direction}
//         delay={marquee.speed}
//         height={window.innerWidth < 600 ? 32 : 72}
//         marqueeContainerClassName="ticker_container"
//         marqueeItemClassName="ticker_item"
//         marqueeClassName="ticker"
//       />
//     </div>
//   )
// }
//
// export default MarqueeTop;

