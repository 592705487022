import React, {Component} from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Slider from "react-slick";

import getStsImage, {stsArr} from '../../utils/sts-photos';
import SlickArrowRight from '../ReviewsList/SlickArrowRight';
import SlickArrowLeft from '../ReviewsList/SlickArrowLeft';

import './style.scss';

const initialState = {
  slides: 1,
};

class StudentWorkList extends Component {
  state = initialState;
  componentDidMount() {
    let windowWidth = window.innerWidth;
    if (windowWidth < 600) this.setState({slides: 1});
    if (windowWidth > 600) this.setState({slides: 4});
  }

  render() {
    const {slides} = this.state;

    const renderSlides = () => {
      let windowWidth = window.innerWidth;
      if (windowWidth < 600) this.setState({slides: 1});
      if (windowWidth > 600) this.setState({slides: 4});
    }

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: slides,
      slidesToScroll: 1,
      prevArrow: <SlickArrowLeft />,
      nextArrow: <SlickArrowRight />,
    };

    window.addEventListener("resize", renderSlides);

    return (
      <div className="student-work">
        <div className="student-work_list-wrap">
          <h2 className="student-work_h2"><span>работы участников</span></h2>
          <GridList className="student-work_list">
            <Slider {...settings}>
              {stsArr.map((tile, i) => (
                <Link key={i} href={getStsImage(i)} className="student-work_link" data-fancybox="gallery">
                  <GridListTile className="student-work_item" style={{width: 416, height: 416}}>
                    <img src={getStsImage(i)} alt="photo" />
                  </GridListTile>
                </Link>
              ))}
            </Slider>
          </GridList>
        </div>
      </div>
    );
  }
}

export default StudentWorkList;
