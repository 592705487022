import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import './style.scss';
import rotateRect1 from '../../../assets/img/rotate-b2.1.svg'
import rotateRect2 from '../../../assets/img/rotate-b2.2.svg'
import rotateRect3 from '../../../assets/img/rotate-b2.3.svg'
import rotateRect4 from '../../../assets/img/rotate-b2.4.svg'

const useStyles = makeStyles((theme) => ({
  rootRotate: {
    width: 'calc('+ window.innerWidth + 'px - 32px)',
    height: 'calc('+ window.innerWidth + 'px - 32px)',
  },
}));

function RotateBanner( {list} ) {
  const classes = useStyles();
  // const renderDescription = () => list.map(item =>
  //   <li className="rotate-banner_item">
  //     <img src={item.address} alt={item.text} />
  //   </li>
  // );

  return (
    <div className={clsx('rotate-banner', classes.rootRotate)}>
      <img id="rotate-rect1" src={rotateRect1} alt=""/>
      <img id="rotate-rect2" src={rotateRect2} alt=""/>
      <img id="rotate-rect3" src={rotateRect3} alt=""/>
      <img id="rotate-rect4" src={rotateRect4} alt=""/>
    </div>
  )
}

export default RotateBanner;
