import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '../../../assets/img/ExpandMore.svg';

import './style.scss';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      cursor: 'pointer'
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

function QuestionAnswerCardFifth() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="question-answer_card">
      <CardActions disableSpacing className="question-answer_head">
        <h3 className="question-answer_h3" onClick={handleExpandClick}>
          а если фотография для меня только хобби — коучинг мне подойдет?
        </h3>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <img src={ExpandMoreIcon} alt="Expand More"/>
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <p className="question-answer_p">
          Тут есть 2 варианта.
        </p>

        <ol className="question-answer_p">
          <li>Ты хочешь начать зарабатывать на фотографии и стать профессиональным фотографом. Тогда сложно найти что-то более подходящее тебе, чем этот коучинг.</li>
          <li>Ты хочешь, чтобы фотография продолжала оставаться твоим хобби. Но вопрос в том, хочешь ли ты, чтобы твои снимки увидела аудитория? Еще больше прокачать свой уровень? Попробовать работать с командой? Тогда коучинг подойдет. Да, мы будем говорить про работу с клиентами и получение заказов, но это только одна из частей коучинга. Он направлен на многостороннее развитие в фотографии.</li>
        </ol>
      </Collapse>
    </div>
  )
}

export default QuestionAnswerCardFifth;
