import React from 'react';

import {ReactComponent as BtnIcon} from "../../../assets/img/arrow-grey.svg";

function SlickArrowLeft({ currentSlide, slideCount, ...props }) {
  return (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <BtnIcon />
    </button>
  )
}

export default SlickArrowLeft;
