import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import $ from "jquery";

import Form from '../../Form';
import Button from '../../Button';
import {fetchRemainingFirst, fetchRemainingSecond} from "../../../redux/remaining";

import './style.scss';

const initialState = {
  open: false,
  remainingSeats: null,
};

const renderDescription = (description) => {
  return description.map((item) => (
    <div className="price-box-card_description">
      {item.subtitle ? <h4 className="price-box-card_h4">{item.subtitle}</h4> : ''}
      <p className="price-box-card_p">{item.text}</p>
    </div>
  ))
};

class PriceBoxCard extends Component {
  state = initialState;

  componentDidMount() {
    const {index} = this.props;
    if (index === 2) {
      this.props.fetchRemainingFirst(index)
    }
    if (index === 3) {
      this.props.fetchRemainingSecond(index)
    }
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  getPlaces = (seats) => {
    const {index, remainingFirst, remainingSecond} = this.props;
    if (index === 1) return;
    //let count = index === 1 ? remainingFirst : remainingSecond;
    //if (seats <= 0) return "Нет";
    //console.log('---- seats', seats)
    if (index === 2) return <span>{remainingFirst > 0 ? `Осталось ${remainingFirst}` : 'Нет'}</span>;
    if (index === 3) return <span>{remainingSecond > 0 ? `Осталось ${remainingSecond}` : 'Нет'}</span>;
  };

  numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  renderSeatsBlock = (index) => {
    const {remainingFirst, remainingSecond} = this.props;
    if (index === 2) {
      return remainingFirst && <div className={`price-box-card_seats tarif-${index}`}>{this.getPlaces(remainingFirst)}</div>
    }
    if (index === 3) {
      return remainingSecond && <div className={`price-box-card_seats tarif-${index}`}>{this.getPlaces(remainingSecond)}</div>
    }
  };

  renderButtonBlock = (index, box) => {
    const {remainingFirst, remainingSecond} = this.props;
    if (index === 1) {
      return <Button
        text={<span>{`Оплатить ${this.numberWithSpaces(box.price)} ₽`}</span>}
        customClassName={`price-box-card_button`}
        handleOpen={this.handleOpen}
      />
    }
    if (index === 2) {
      return <Button
        text={remainingFirst > 0  ? <span>{`Оплатить ${this.numberWithSpaces(box.price)} ₽`}</span> : 'мест нет'}
        customClassName={`price-box-card_button`}
        handleOpen={this.handleOpen}
        disabled={!(remainingFirst > 0)}
      />
    }
    if (index === 3) {
      return <Button
        text={remainingSecond > 0  ? <span>{`Оплатить ${this.numberWithSpaces(box.price)} ₽`}</span> : 'мест нет'}
        customClassName={`price-box-card_button ${box.vip}`}
        handleOpen={this.handleOpen}
        disabled={!(remainingSecond > 0)}
      />
    }
  };

  getClass = (i) => {
    switch (i) {
      case 1:
        return 'tumb-1';
        break;
      case 2:
        return 'tumb-2';
        break;
      case 3:
        return 'tumb-3';
        break;
      default:
        return '';
    }
  }

  render() {
    const {open} = this.state;
    const {box, index, remainingFirst, remainingSecond} = this.props;

    const body = (
      <div className="form-modal">
        <Form handleClose={this.handleClose} box={box} index={index} />
      </div>
    );

    return (
      <Fragment>
        <div className={clsx('price-box', this.getClass(index))}>
          <Container maxWidth="lg" component="section" className="price-box-section">
            <Grid item xl={12} sm={12}>
              <div className="price-box-card">
                <h3 className="price-box-card_h3">{box.title}</h3>
                {renderDescription(box.description)}
                {this.renderSeatsBlock(index)}
              </div>
            </Grid>
          </Container>
          {this.renderButtonBlock(index, box)}
        </div>
        <Modal
          open={open}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = ({remaining}) => ({
  remainingFirst: remaining.dataFirst,
  remainingSecond: remaining.dataSecond
});

const mapDispatchToProps = {
  fetchRemainingFirst,
  fetchRemainingSecond,
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceBoxCard);
