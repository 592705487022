import axios from 'axios';
import urlHost from '../utils/constants';

const urlModule = 'remaining';

const FETCH_REMAINING_FIRST = `${urlModule}/FETCH_REMAINING_FIRST`;
const FETCH_REMAINING_SUCCESS_FIRST = `${urlModule}/FETCH_REMAINING_SUCCESS_FIRST`;
const FETCH_REMAINING_FAIL_FIRST = `${urlModule}/FETCH_REMAINING_FAIL_FIRST`;

const FETCH_REMAINING_SECOND = `${urlModule}/FETCH_REMAINING_SECOND`;
const FETCH_REMAINING_SUCCESS_SECOND = `${urlModule}/FETCH_REMAINING_SUCCESS_SECOND`;
const FETCH_REMAINING_FAIL_SECOND = `${urlModule}/FETCH_REMAINING_FAIL_SECOND`;

const initialState = {
  dataFirst: null,
  dataSecond: null,
  isRemainingFetching: false,
  remainingError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REMAINING_FIRST:
      return {...state, isRemainingFetching: true, remainingError: null}
    case FETCH_REMAINING_SUCCESS_FIRST:
      return {...state, isRemainingFetching: false, dataFirst: action.dataFirst}
    case FETCH_REMAINING_FAIL_FIRST:
      return {...state, isRemainingFetching: false, remainingError: action.error}

    case FETCH_REMAINING_SECOND:
      return {...state, isRemainingFetching: true, remainingError: null}
    case FETCH_REMAINING_SUCCESS_SECOND:
      return {...state, isRemainingFetching: false, dataSecond: action.dataSecond}
    case FETCH_REMAINING_FAIL_SECOND:
      return {...state, isRemainingFetching: false, remainingError: action.error}
    default:
      return state
  }
}

export const fetchRemainingFirst = (values) => dispatch => {
  dispatch({type: FETCH_REMAINING_FIRST});
  axios
    .get(`${urlHost}/api/courses/${values}/remaining-members`)
    .then(({data}) => {
      dispatch({type: FETCH_REMAINING_SUCCESS_FIRST, dataFirst: data})
    })
    .catch(error => dispatch({type: FETCH_REMAINING_FAIL_FIRST, remainingError: error}))
};

export const fetchRemainingSecond = (values) => dispatch => {
  dispatch({type: FETCH_REMAINING_SECOND});
  axios
    .get(`${urlHost}/api/courses/${values}/remaining-members`)
    .then(({data}) => {
      dispatch({type: FETCH_REMAINING_SUCCESS_SECOND, dataSecond: data})
    })
    .catch(error => dispatch({type: FETCH_REMAINING_FAIL_SECOND, remainingError: error}))
};
