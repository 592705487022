import React, { Fragment } from 'react';
import MuiButton from '@material-ui/core/Button';

import './style.scss';

function Button({ text, item, customClassName, children, handleOpen, ...props }) {
  return (
    <Fragment>
      <MuiButton
        {...props}
        className={`some-button ${customClassName}`}
        disableRipple
        onClick={handleOpen}
      >
        {text || item}
      </MuiButton>
    </Fragment>
  )
}

export default Button;
