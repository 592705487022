import axios from 'axios';
import urlHost from '../utils/constants';

const urlModule = 'comments';

const FETCH_COMMENTS = `${urlModule}/FETCH_COMMENTS`;
const FETCH_COMMENTS_SUCCESS = `${urlModule}/FETCH_COMMENTS_SUCCESS`;
const FETCH_COMMENTS_FAIL = `${urlModule}/FETCH_COMMENTS_FAIL`;

const initialState = {
  data: null,
  isCommentsFetching: false,
  commentsError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMENTS:
      return {...state, isCommentsFetching: true, commentsError: null}
    case FETCH_COMMENTS_SUCCESS:
      return {...state, isCommentsFetching: false, data: action.data}
    case FETCH_COMMENTS_FAIL:
      return {...state, isCommentsFetching: false, commentsError: action.error}
    default:
      return state
  }
}
export const fetchComments = () => dispatch => {
  dispatch({type: FETCH_COMMENTS})
  axios
    .get(`${urlHost}/api/comments`)
    .then(({data}) => {
      dispatch({type: FETCH_COMMENTS_SUCCESS, data: data})
    })
    .catch(error => dispatch({type: FETCH_COMMENTS_FAIL, commentsError: error}))
}
