import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import './style.scss';

function PromotionalBlock() {
  return (
    <div className="promotional-block-wrap">
      <Container maxWidth="lg" component="section" className="promotional">
        <Grid
          container
          direction="row"
        >
          <Grid item sm={12} lg={6}>
            <h2 className="promotional_h2">
              <span>курсы не работают</span>
            </h2>
          </Grid>
          <Grid item sm={12} lg={6}>
            <p className="promotional_p">контента о том, как ставить свет, обрабатывать фотки, строить личный бренд и раскручивать соцсети — завались. уверен, что ты уже достойно снимаешь. но почему ты не в топе?</p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default PromotionalBlock;
