import React from 'react';
import {
  Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { createBrowserHistory } from 'history';
//import createBrowserHistory from 'history/createBrowserHistory'
import { StylesProvider } from '@material-ui/core/styles';

import AppContent from '../AppContent'
import Reviews from '../Reviews'
import Offer from '../Offer'
import SuccessfulBid from '../SuccessfulBid'
import NotPaid from '../NotPaid'
import PaidTariffFirst from '../PaidTariffFirst'
import PaidTariffSecond from '../PaidTariffSecond'
import PaidTariffThird from '../PaidTariffThird'

const history = createBrowserHistory()
window.routerHistory = history;

function App() {
  return (
    <StylesProvider injectFirst>
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={AppContent} />
          <Route path="/reviews" exact component={Reviews} />
          <Route path="/offer" exact component={Offer} />
          <Route path="/successful-bid" exact component={SuccessfulBid} />
          <Route path="/not-paid" exact component={NotPaid} />
          <Route path="/paid-tariff-first" exact component={PaidTariffFirst} />
          <Route path="/paid-tariff-second" exact component={PaidTariffSecond} />
          <Route path="/paid-tariff-third" exact component={PaidTariffThird} />
        </Switch>
      </Router>
    </StylesProvider>
  );
}

export default App;
