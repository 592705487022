import React from 'react';
import Container from '@material-ui/core/Container';

import '../../styles/paid-page.scss';
import noPaid from "../../assets/img/no-paid.png";

function NotPaid() {
  return (
    <div className="paid-page">
      <Container maxWidth="lg" component="section" className="paid-page_container">
        <img src={noPaid} alt="paid" className="paid-page_icon"/>
        <h1 className="paid-page_h1">оплата не прошла</h1>
        <p className="paid-page_support-service">служба поддержки</p>
        <p className="paid-page_email">
          <a href="mailto:help@tsaplineducation.ru" className="paid-page_email-link">help@tsaplineducation.ru</a>
        </p>
        <p className="paid-page_tel">
          <a href="https://t.me/utc13" className="paid-page_email-link" target="_blank">
            telegram
          </a>
        </p>
        <p className="paid-page_tel">
          <a href="https://vk.me/public122517309" className="paid-page_email-link" target="_blank">
            vkontakte
          </a>
        </p>
      </Container>
    </div>
  );
}

export default NotPaid;
