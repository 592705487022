import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

import MarqueeTop from '../../components/MarqueeTop';
import Header from '../../components/Header';
import VideoBlock from '../../components/VideoBlock';
import PromotionalBlock from '../../components/PromotionalBlock';
import PromotionalOffer from '../../components/PromotionalOffer';
import DetailedDescription from '../../components/DetailedDescription';
import MarqueeMain from '../../components/MarqueeMain';
import ChooseFormat from '../../components/ChooseFormat';
import PriceBox from '../../components/PriceBox';
import ReviewsList from "../../components/ReviewsList";
import StudentWorkList from "../../components/StudentWorkList";
import QuestionAnswer from "../../components/QuestionAnswer";
import HaveAnyQuestions from "../../components/HaveAnyQuestions";
import CallToAction from '../../components/CallToAction';

function LeftContent() {
  return (
    <div className="left-content">
      <YMInitializer accounts={[67145194]} version="2" />
      <MarqueeTop />
      <Header />
      <VideoBlock />
      <PromotionalBlock />
      <PromotionalOffer />
      <DetailedDescription />
      <MarqueeMain />
      <ChooseFormat />
      <PriceBox />
      <ReviewsList />
      <StudentWorkList />
      <QuestionAnswer />
      <HaveAnyQuestions />
      <CallToAction />
    </div>
  );
}

export default LeftContent;
